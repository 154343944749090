<template>
  <div>
    <b-card class="animated fadeIn" style="min-height: 750px;" bg-variant="dark">
      <!-- <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> Yapay Zeka Modelleri Arama Ekranı
      </b-card-header> -->
      <b-row style="margin-bottom: 20px;">
        <b-col sm="12" md="12">
          <img src="../../static/img/slider/waidashboard.png" style="border-radius: 10px; width: 100%; max-height: 500px">
        </b-col>
      </b-row>
      <b-row style="margin-top: 30px;">
        <b-col sm="12" md="1">
        </b-col>
        <b-col sm="12" md="8">
          <b-row style="margin-bottom: 30px;">
            <b-col sm="12" style="text-align: center;">
              <strong>WAI</strong>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 30px;">
            <b-col sm="12">
              <b-form-group label="" label-for="search_patient">
                <b-input-group>
                  <b-form-input style="height: 40px; border-radius: 10px;" id="wisdomera_ai" placeholder="Konuşmak istediğiniz karar destek algoritmaları, yapay zeka modelleri hakkında arama yapabilirsiniz" type="text" v-model="d_searchWisdomEraAiRegion" @keydown.enter.native="ClickEnter"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="white" @click="f_searchWaim()" style="margin-right: 10px;"><i class="fa fa-search"></i> </b-button>
                    <b-button variant="white" @click="d_searchWisdomEraAiRegion = ''" style="margin-right: 10px;"><i class="fa fa-trash"></i> </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 20px;">
            <b-col v-for="(x, x_ind) in d_treeListData.list" cols="12" sm="12" lg="5" style="margin: 20px;">
              <b-card bg-variant="dark" footer-class="px-3 py-2" style="height: 100%;">
                <b-row style="margin: 5px;">
                  <b-col sm="12" md="12">
                    <strong> {{ x.wdmr.label }} </strong>
                  </b-col>
                </b-row>
                <template v-if="x.wdmr.data && x.wdmr.data.general && x.wdmr.data.general.info && x.wdmr.data.general.info.list && x.wdmr.data.general.info.list.length > 0">
                  <template v-for="(info, info_ind) in x.wdmr.data.general.info.list">
                    <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px;">
                      <b-col sm="12" md="12">
                        <div v-html="f_getInfoHtml(info.explanation.val)"></div>
                      </b-col>
                    </b-row>
                    <b-row v-if="info && info.image && info.image.val" style="margin: 5px;">
                      <b-col sm="12" md="12">
                        <img :src="info.image.val" style="max-width: 75%;">
                      </b-col>
                    </b-row>
                  </template>
                </template>
                <div slot="footer">
                  <b-link class="font-weight-bold font-xs btn-block text-muted" target="_blank" :href="'https://docs.wisdomera.io/#/documenttree?wdmr231=' + x.key.split('_')[1]">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="3">
          <wai-list :p_waiList="d_waiData.wai"></wai-list>
        </b-col>
      </b-row>
      <web-bottom></web-bottom>
    </b-card>
  </div>
</template>

<script>
import WdmService from '@/services/wdm';
import WebBottom from '@/components/widgets/WebBottom';
import {
  default as WaiList
} from '@/components/widgets/WaiList';
import {
  default as DrawAlgorithm
} from '@/components/widgets/DrawAlgorithm';
import WaiService from '@/services/wai';
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import {
  default as PolestarFastAnalyze
} from "@/components/widgets/PolestarFastAnalyze";
import vSelect from 'vue-select';
import PoleStarService from '@/services/polestar';
import { ClsRule } from '@/services/public/rule';
import { ClsPolestar } from "@/services/public/polestar";
import { mapGetters } from 'vuex';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as PolestarResult
} from "@/components/widgets/PolestarResult";
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from 'moment';

export default {
  name: 'Dashboard',
  components: {
    Modal,
    WdmRuleA,
    PolestarResult,
    vSelect,
    PolestarFastAnalyze,
    DrawAlgorithm,
    WaiList,
    WebBottom
  },
  props: {
    p_waimList: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    if (this.p_waimList && this.p_waimList.length > 0) {
      this.d_waimList = JSON.parse(JSON.stringify(this.p_waimList));
    }
    if (this.$route.query.waim_text) {
      this.d_searchWisdomEraAiRegion = this.$route.query.waim_text;
      this.f_searchWaim();
      // this.$route.query = {};
      // this.$router.push({ 'path': '/whsmodules/polestar/waim', 'query': {} });
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getWdmrTreeList();
  },
  mounted: function () {
    this.f_getNextWai();
    this.f_getNextWaiStartInterval();
  },
  destroyed () {
    clearInterval(this.d_nextWaiInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  data () {
    return {
      user: {},
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0, 'article_naming': 0 },
      d_searchWisdomEraAiRegion: '',
      d_waimList: [],
      d_selectedProjectData: {},
      column_list: [],
      option_data: {},
      d_algorithmData: {
        'show': true,
        'data': [],
        'dmp_rule_slider': 'all_dmp',
        'stop': 0,
        'next': 0,
        'update_canvas': 0,
        'name': ''
      },
      d_drawSettings: {
        'canvas': {
          'height': 250,
          'width': window.innerWidth
        },
        'header_variant': 'dark',
        'biggest_circle_radius': 30,
        'show_text': 1,
        'size': 200,
        'animation': 0,
        'lineWidth': 3,
        'colorAnimation': 1,
        'colorSpeed': 500,
        'rotateAnimation': 1,
        'shift': 1,
        'mainColor': '',
        'speed': 100,
        'deep_animation_speed': 500,
        'max_deep': 0,
        'deep': 0,
        'deep_direction': 'to_children',
        'deep_animation': 0,
        'show_buttons': 0
      },
      d_waiData: {
        'last_wai': '0',
        'wai': [],
        'count': 5,
        'published_dmp_count': 0
      },
      d_nextWaiInterval: 0,
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'list': [],
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_25',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      }
    }
  },
  methods: {
    f_getWdmrTreeList: function () {
      let data = {
        'parent_wdm': this.d_treeListData.parent,
        'child_wdm': this.d_treeListData.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list_remote(data)
        .then(resp => {
          if (resp.data.status === "success") {
            if (resp.data.result.status === 'success') {
              this.d_treeListData.list = resp.data.result.result.list;
            }
          } else {
            console.log('errorr get_wdmr_tree_list_remote : ', resp.data.message);
          }
        });
    },
    f_getInfoHtml: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe max-width="100%" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        let starting_text = txt.substring(0, txt.indexOf(replace_text));
        if (starting_text.length > 150) {
          starting_text = starting_text.substring(0, 150) + '...';
        }
        return starting_text + iframeMarkup;
      } else {
        // console.log(txt)
        if (txt.length > 300) {
          return txt.substring(0, 300) + '...';
        } else {
          return txt;
        }
      }
    },
    f_goToDocumentDetails: function (wdmr231_key) {
      let route_data = {
        'name': 'documenttree',
        'path': 'documenttree',
        'query': {
          'wdmr231': wdmr231_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    },
    f_getNextWai: function () {
      let query = 'last_wai=' + this.d_waiData.last_wai.toString() + '&count=' + this.d_waiData.count.toString();
      WaiService.get_next_wai(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_waiData.last_wai = resp.data.result.last_wai;
            this.d_waiData.wai = resp.data.result.wai;
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_getNextWaiStartInterval: function () {
      this.d_nextWaiInterval = setInterval(function () {
        this.f_getNextWai();
      }.bind(this), 10000);
    },
    ClickEnter: function () {
      if (event.which === 13) {
        this.f_searchWaim();
      }
    },
    f_searchWaim: function () {
      if (this.d_searchWisdomEraAiRegion) {
        this.d_waimList = [];
        let data = { 'search': this.d_searchWisdomEraAiRegion };
        if (!this.$route.query.waim_text || (this.$route.query.waim_text && this.$route.query.waim_text !== this.d_searchWisdomEraAiRegion)) {
          this.$router.push({ 'path': '/wai/search', 'query': { 'waim_text': this.d_searchWisdomEraAiRegion } });
        }
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yapay zeka modellerinde arama işlemi başlatıldı. Lütfen bekleyiniz.' } });
        WaiService.search_wai(data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_waimList = resp.data.result.list;
              this.$forceUpdate();
            } else {
              alert('Error :' + resp.data.message)
            }
          });
      } else {
        alert('Lütfen bir şeyler yazın');
      }
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    f_goToWaimWdm3Project: function (wdm3_id) {
      let route_data = {
        'name': 'polestarwaimtalk',
        'path': '/whsmodules/polestar/waim/talk',
        'query': { 'waim_id': wdm3_id }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm24: function (wdm24_id) {
      let route_data = {
        'name': 'polestarwaimtalkalgorithm',
        'path': '/whsmodules/polestar/waim/talk-algorithm',
        'query': { 'waim_id': wdm24_id }
      };
      this.$router.push(route_data);
    }
  },
  watch: {}
};

</script>

<style type="text/css">


</style>

