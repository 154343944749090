import PoleStarService from '../polestar';
import store from '../../store';

class ClsPolestar {
  static f_getUserPolestarProjects = function(username) {
    return new Promise((resolve, reject) => {
      let data = { 'username': username };
      PoleStarService.get_user_polestar_projects(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            resolve(resp.data.result);
          } else {
            reject(resp.data.message);
          }
        });
    });
  };

  static f_prepareUserPermissionsForThisProject = function(project_data, username) {
    let perms = [];
    if (username === project_data.owner) {
      perms.push('owner');
    } else {
      try {
        for (let i in project_data.data.general.members.list) {
          if (project_data.data.general.members.list[i].member.val.value === username) {
            for (let p in project_data.data.general.members.list[i].member_permission.val) {
              perms.push(project_data.data.general.members.list[i].member_permission.val[p].value);
            }
          }
        }
      } catch (err) {}
    }
    return perms;
  };

  static f_isUserMemberOfThisProject = function(project_data, username) {
    if (username === project_data.owner) {
      return true;
    } else {
      if (project_data.data.general && project_data.data.general.members && project_data.data.general.members.list) {
        for (let i in project_data.data.general.members.list) {
          if (project_data.data.general.members.list[i].member.val.value === username) {
            return true;
          }
        }
      }
    }
    return false;
  };

  static f_controlColumnFeatures = function(column_list, col_index, type = 'all') {
    if (type === 'this') {
      this.f_columnControl(column_list[col_index])
    } else {
      for (let i in column_list) {
        this.f_columnControl(column_list[i])
      }
    }
  };

  static f_columnRuleDesign = function(query_list, option_data) {
    return new Promise(function(resolve, reject) {

    });
    if (this.column_compile_str_array.length === 0) {
      this.add_key_compile_str('and', 'column_rule');
    } else {
      // burada ilgil compile str array uygun şekilde dizayn edildi mi kontrolü yapılacak. parantezler vesaire.
    }
    let x = {
      'compile_str_array': JSON.parse(JSON.stringify(this.column_compile_str_array)),
      'query_list': JSON.parse(JSON.stringify(query_list))
    };
    let det = false;
    let column_field_data = '';
    let wdm_name = '';
    let wdm_type = '';
    let field_operation = '=';
    let column_val_analyze_type = '';
    let parameter_val_list = [];
    let parameter_values = [];
    // FIRST WE FIND IS_COLUMN FIELD
    for (let i in query_list) {
      for (let f in query_list[i].fields) {
        if (query_list[i].fields[f].is_column) {
          det = true;
          if (query_list[i].fields[f].val_list.length > 0) {
            field_operation = query_list[i].fields[f].operation;
            parameter_val_list = query_list[i].fields[f].val_list;
            parameter_values = query_list[i].fields[f].values;
            column_val_analyze_type = query_list[i].fields[f].column_val_analyze_type;
          }
          column_field_data = query_list[i].fields[f];
          wdm_name = query_list[i].type;
          wdm_type = query_list[i].type.value;
          break;
        }
      }
      if (det) {
        break;
      }
    }
    let options = [];
    let param_type = '';
    if (column_val_analyze_type === 'yesno') {
      param_type = 'select';
      options = ['evet', 'hayir'];
    } else if (column_val_analyze_type === 'count') {
      param_type = 'integer';
    } else if (column_val_analyze_type === 'sum') {
      param_type = 'float';
    } else {
      param_type = column_field_data.value_type;
    }
    let column_label = this.f_createLabelFromLoc(column_field_data.loc);
    if (this.column_rule_operation_type === 'edit') {
      this.column_list[this.d_selectedColumnInd].label = column_label;
      this.column_list[this.d_selectedColumnInd].parameter_val_list = parameter_val_list;
      this.column_list[this.d_selectedColumnInd].parameter_values = parameter_values;
      this.column_list[this.d_selectedColumnInd].column_val_analyze_type = column_val_analyze_type;
      this.column_list[this.d_selectedColumnInd].field_operation = field_operation;
      this.column_list[this.d_selectedColumnInd].loc = column_field_data.loc;
      this.column_list[this.d_selectedColumnInd].loc = column_field_data.loc;
      this.column_list[this.d_selectedColumnInd].wdm_type = wdm_type;
      this.column_list[this.d_selectedColumnInd].wdm_name = wdm_name;
      this.column_list[this.d_selectedColumnInd].type = 'wdm';
      this.column_list[this.d_selectedColumnInd].param_type = param_type;
      this.column_list[this.d_selectedColumnInd].date_ability = option_data[wdm_type].date_ability ? option_data[wdm_type].date_ability : '';
      this.column_list[this.d_selectedColumnInd].date_ability_limit = option_data[wdm_type].date_ability_limit ? option_data[wdm_type].date_ability_limit : '';
      this.column_list[this.d_selectedColumnInd].date_ability_type = option_data[wdm_type].date_ability_type ? option_data[wdm_type].date_ability_type : '';
      this.column_list[this.d_selectedColumnInd].record_count = option_data[wdm_type].record_count ? option_data[wdm_type].record_count : '';
      this.column_list[this.d_selectedColumnInd].rule = x;
      if (column_field_data.anatomy_use) {
        this.column_list[this.d_selectedColumnInd].anatomy_use = column_field_data.anatomy_use;
      }
      if (options.length > 0) {
        this.column_list[this.d_selectedColumnInd].options = options;
      }
      if (param_type !== column_field_data.value_type) {
        this.column_list[this.d_selectedColumnInd].param_type_real = column_field_data.value_type;
      }
    } else {
      let new_col_data = {
        'anonym': 0,
        'label': column_label,
        'parameter_val_list': parameter_val_list,
        'parameter_values': parameter_values,
        'column_val_analyze_type': column_val_analyze_type,
        'loc': column_field_data.loc,
        'operation': field_operation,
        'type': 'wdm',
        'wdm_type': wdm_type,
        'wdm_name': wdm_name,
        'param_type': param_type,
        'date_ability': option_data[wdm_type].date_ability ? option_data[wdm_type].date_ability : '',
        'date_ability_limit': option_data[wdm_type].date_ability_limit ? option_data[wdm_type].date_ability_limit : '',
        'date_ability_type': option_data[wdm_type].date_ability_type ? option_data[wdm_type].date_ability_type : '',
        'record_count': option_data[wdm_type].record_count ? option_data[wdm_type].record_count : '',
        'rule': x
      };
      if (['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(param_type) !== -1) {
        new_col_data.value_label = 'label';
      }
      if (new_col_data.record_count === 'many_times') {
        if (['integer', 'float'].indexOf(param_type) !== -1) {
          new_col_data.numeric_string = 'numeric';
          new_col_data.numeric_usage = 'mean';
        } else {
          new_col_data.numeric_string = 'string';
          new_col_data.string_usage = '2';
          new_col_data.multi_wdmr_approach = '1';
          new_col_data.list_creation_approach = '1';
        }
      }
      if (options.length > 0) {
        new_col_data.options = options;
      }
      if (param_type !== column_field_data.value_type) {
        new_col_data.param_type_real = column_field_data.value_type;
      }
      if (column_field_data.anatomy_use) {
        new_col_data.anatomy_use = column_field_data.anatomy_use;
      }
      this.column_list.push(new_col_data);
    }
    this.d_showNewColumnRuleModal = false;
    this.$forceUpdate();
  };

  static f_columnControl = function(column_data) {
    if (column_data.type === 'wdm') {
      if (column_data.record_count === 'many_times') {
        if (column_data.numeric_string === 'numeric') {
          if (!column_data.numeric_usage) {
            column_data.numeric_usage = 'biggest';
          }
          delete column_data['string_usage'];
        }
        if (column_data.numeric_string === 'string') {
          if (!column_data.string_usage) {
            column_data.string_usage = '0';
          }
          delete column_data['numeric_usage'];
        }
        if (column_data.numeric_string === 'numeric' || (column_data.string_usage && column_data.string_usage !== '2')) {
          delete column_data['multi_wdmr_approach'];
          delete column_data['list_creation_approach'];
        }
      } else {
        delete column_data['numeric_string'];
        delete column_data['string_usage'];
        delete column_data['numeric_usage'];
        delete column_data['multi_wdmr_approach'];
        delete column_data['list_creation_approach'];
      }
    }
  };

  static f_prepareColumnRuleOptionData = function(d_columnRule, column_list) {
    d_columnRule.wdm_list = [];
    for (let i in column_list) {
      d_columnRule.wdm_list.push({ 'label': column_list[i].label, 'value': i.toString(), 'type': i.toString() });
      let column_index = i.toString();
      let data_type = i.toString();
      d_columnRule['data_type'] = data_type;
      d_columnRule.option_data[data_type] = {
        "date_ability": column_list[column_index].date_ability ? column_list[column_index].date_ability : '',
        "param_group": {
          "param_order": ["general"],
          "parameters": { "general": { "value": "general", "label": "Genel" } }
        },
        "general": {
          "param_order": [],
          "parameters": {}
        },
        "name": {
          "value": i.toString(),
          "label": column_list[i].label
        }
      };
      d_columnRule.prepare_data['data'] = { 'general': {} };
      let param_data = {
        "name": {
          "value": i.toString(),
          "label": column_list[i].label
        },
        "show": "yes",
        "type": ""
      };
      if (['select', 'radiogroup', 'checkbox', 'object_list_options'].indexOf(column_list[column_index].param_type) !== -1) {
        d_columnRule.option_data[data_type].general.param_order.push(column_index);
        d_columnRule.option_data[data_type].general.parameters[column_index] = param_data;
        d_columnRule.option_data[data_type].general.parameters[column_index]['type'] = 'select';
        d_columnRule.option_data[data_type].general.parameters[column_index].options = column_list[column_index].options;
      } else if ((['integer', 'float', 'text', 'textarea', 'plain_text', 'datetime', 'date'].indexOf(column_list[column_index].param_type) !== -1)) {
        d_columnRule.option_data[data_type].general.param_order.push(column_index);
        d_columnRule.option_data[data_type].general.parameters[column_index] = param_data;
        d_columnRule.option_data[data_type].general.parameters[column_index]['type'] = column_list[column_index].param_type;
      }
    }
  };

  static f_createProjectWdmList = function(project_data) {
    let wdm_list = [];
    try {
      for (let i in project_data['data']['general']['wdm_version_list']['list']) {
        /*
          SELECTED_WDM STRUCTURE SHOULD BE..
          {
              "label": "genetik ( 4.0)",
              "value": "wdm_wisdom_genetic_v4.0",
              "bucket": "option",
              "type": "genetic"
          }
        */
        let vers_data = project_data['data']['general']['wdm_version_list']['list'][i]['wdm_version']['val'];
        let x = {
          'label': project_data['data']['general']['wdm_version_list']['list'][i]['wdm_type']['val']['label'] + ' ' + vers_data.version,
          'value': vers_data.value,
          'type': project_data['data']['general']['wdm_version_list']['list'][i]['wdm_type']['val']['value'],
          'bucket': ''
        };
        if (project_data['data']['general']['wdm_version_list']['list'][i]['wdm_group']['val']['value'] === 'wisdom') {
          x.bucket = 'option';
        } else {
          x.bucket = 'hospital';
        }
        wdm_list.push(JSON.parse(JSON.stringify(x)));
      }
    } catch (err) {}
    return wdm_list;
  };
}

export { ClsPolestar };
