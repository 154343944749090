<template>
  <div>
    <b-card no-body>
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        <strong>Analiz edilen parametre (Hedef) </strong> : {{ p_mlData.target.label }}
        <b-button class="pull-right" variant="success" size="md" @click="f_analyzeMachineLearning()">analiz yap</b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12" md="6">
          <strong>Accuracy (Doğruluk)</strong>
        </b-col>
        <b-col sm="12" md="6">
          {{ p_mlData.accuracy }}
        </b-col>
      </b-row>
      <b-row v-if="p_mlData.roc && p_mlData.roc.auc" style="margin: 0px;">
        <b-col sm="12" md="6">
          <strong>Roc (auc)</strong>
        </b-col>
        <b-col sm="12" md="6">
          {{ p_mlData.roc.auc }}
        </b-col>
      </b-row>
      <b-row style="margin: 0px;">
        <b-col sm="12" md="6">
          <strong>Analiz tipi</strong>
        </b-col>
        <b-col sm="12" md="6">
          {{ p_mlData.type }}
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        Girdi Parametreleri
      </b-card-header>
      <template v-for="(inp, inp_index) in p_mlData.input_list">
        <b-row style="margin: 0px;">
          <b-col sm="12" md="6">
            <strong> {{ inp.label }} </strong>
          </b-col>
          <b-col sm="12" md="6">
            <template v-if="inp.param_type">
              <template v-if="inp.param_type === 'select'">
                <b-form-select v-model="inp.selected">
                  <option v-for="(op, op_ind) in inp.options" :value="op.value">
                    {{ op.label }}
                  </option>
                </b-form-select>
              </template>
              <template v-else-if="inp.param_type === 'float'">
                <b-form-input type="number" v-model="inp.selected"></b-form-input>
              </template>
              <template v-else-if="inp.param_type === 'integer'">
                <b-form-input type="number" v-model="inp.selected"></b-form-input>
              </template>
            </template>
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-card no-body v-if="d_mlResult.predict">
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        Analiz sonucu
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col cols="12">
          {{ d_mlResult.predict }}
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import PoleStarService from '@/services/polestar';
export default {
  name: 'MachineLearningPredict',
  components: {},
  props: {
    p_mlData: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      d_mlResult: { 'predict': '' }
    }
  },
  computed: {},
  methods: {
    f_analyzeMachineLearning: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_machine_learning(this.p_mlData)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_mlResult.predict = resp.data.result.predict;
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    }
  }
}

</script>

